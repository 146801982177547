var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = "/sentry/monitoring-tunnel";
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"2c34241754d10ad1548d20dadd1bcc2c3bc0844e"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from '@sentry/nextjs';

const SENTRY_DSN =
  process.env.SENTRY_DSN ||
  process.env.NEXT_PUBLIC_SENTRY_DSN ||
  'https://722a81bf37c743ed926a762be6cd1fc1@o409459.ingest.sentry.io/5774512';

Sentry.init({
  environment: process.env.NEXT_PUBLIC_SENTRY_ENV,
  dsn: SENTRY_DSN,
  tracesSampleRate: 1.0,
});
